import { useMemo } from 'react';

export const useWidgetBaseUrl = () => {
  const { origin } = window.location;

  const chatWidgetBaseUrl = useMemo(() => {
    if (origin === 'localhost') {
      return `http://localhost:5555`;
    }

    return origin.replace(/careers/, 'widget');
  }, [origin]);

  return { chatWidgetBaseUrl };
};
