import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'types';

import { useWidgetBaseUrl } from 'hooks/useWidgetBaseUrl';

declare global {
  interface Window {
    fountainChatWidgetId: string;
    fountainChatWidgetBaseUrl: string;
    fountainAIWidgetOpen: boolean;
    fountainAi: {
      isFaqOnlyWidget: boolean;
      customWidth: string;
    };
  }
}

export function ChatWidget() {
  const { brandId } = useParams<RouteParams>();
  const { chatWidgetBaseUrl } = useWidgetBaseUrl();

  useEffect(() => {
    window.fountainChatWidgetId = brandId;
    window.fountainChatWidgetBaseUrl = chatWidgetBaseUrl;
    window.fountainAi = {
      isFaqOnlyWidget: true,
      customWidth: '800',
    };

    (function () {
      const js = document.createElement('script');
      js.type = 'text/javascript';
      js.async = true;
      js.src = `${chatWidgetBaseUrl}/ChatWidgetShim.js`;

      const before = document.getElementsByTagName('script')[0];
      before.parentNode?.insertBefore(js, before);
    })();
  }, [brandId, chatWidgetBaseUrl]);

  return <></>;
}
