import '../index.css';

import { OpenAPI } from 'api-clients/monolith';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom';

import { AppContextProvider } from 'components/app-context-provider';
import { domainMeta } from 'utils/domainMeta';

import { Root } from './root';
import { initializeDatadog } from './utils/datadog';

if (domainMeta.datadog.isEnabled) {
  const { applicationId, clientToken, sampleRate, site } = domainMeta.datadog;
  initializeDatadog({
    allowedTracingOrigins: [domainMeta.monolithOrigin],
    applicationId,
    clientToken,
    env: domainMeta.environment,
    sampleRate,
    service: 'career-site-ui',
    site,
    tenant: domainMeta.tenant,
    version: process.env.SHORT_SHA,
  });
}

const MOUNT_NODE = document.getElementById('app');
OpenAPI.BASE = domainMeta.monolithOrigin;

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <IntlProvider locale="en">
        <AppContextProvider>
          <div className="flex min-h-screen flex-col">
            <Root />
          </div>
        </AppContextProvider>
      </IntlProvider>
    </Router>
  </React.StrictMode>,
  MOUNT_NODE,
);
